var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-container kt-grid__item kt-grid__item--fluid",
      on: {
        dragleave: _vm.onDragLeave,
        dragover: _vm.onDragOver,
        drop: _vm.onDrop,
      },
    },
    [
      _vm.showDropZone
        ? _c("div", { staticClass: "w-100" }, [
            _c(
              "a",
              { staticClass: "kt-header__brand-logo w-100 text-center" },
              [
                _c("SVGIcon", {
                  staticClass: "logo loading",
                  attrs: { name: "dropZone" },
                }),
                _c("h1", { staticClass: "kt-font-bolder" }, [
                  _vm._v(" Drop files here to upload! "),
                ]),
              ],
              1
            ),
          ])
        : [
            _vm.status
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "alert alert-light alert-elevate fade show",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", {
                            class: {
                              "la la-check kt-font-success":
                                _vm.status == "success",
                              "la la-upload kt-font-warning":
                                _vm.status == "processing",
                              "la la-times kt-font-danger":
                                _vm.status == "danger",
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "alert-text",
                            class: {
                              "kt-font-bold": _vm.status == "danger",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.status == "success"
                                    ? "PDF Upload Successful. Please allow time as they populate to student portfolios."
                                    : _vm.status == "processing"
                                    ? "PDF Upload Processing"
                                    : "PDF Upload Failed"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.errorFiles.length == 0
                      ? "col-12"
                      : "col-xl-6 col-lg-6 col-md-6",
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "kt-portlet kt-portlet--height-fluid" },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("input", {
                          staticStyle: { display: "none" },
                          attrs: {
                            id: "file-input",
                            type: "file",
                            name: "file",
                            accept: ".pdf",
                          },
                          on: { change: _vm.onFileBrowseSelect },
                        }),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-6 py-3" }, [
                            _c("label", [_vm._v("PDF File Type")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedPdfType,
                                    expression: "selectedPdfType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedPdfType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(
                                _vm.officialPdfTypes,
                                function (pdfType, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: `pdfType_${pdfType.officialPdfTypeId}_${idx}`,
                                      domProps: { value: pdfType },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(pdfType.pdfType) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "col-6 py-3" }, [
                            _c("label", [_vm._v("PDF File")]),
                            _c(
                              "div",
                              {
                                staticClass: "input-group",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.triggerFileBrowser.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.file
                                      ? `${_vm.file.name}`
                                      : "Choose Files",
                                    readonly: "readonly",
                                  },
                                }),
                                _vm._m(1),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "py-3",
                              class: {
                                "col-4":
                                  _vm.selectedPdfType?.pdfType ===
                                  "Student Report Cards",
                                "col-6":
                                  _vm.selectedPdfType?.pdfType !==
                                  "Student Report Cards",
                              },
                            },
                            [
                              _c("label", [_vm._v("School Year")]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedSchoolYear,
                                      expression: "selectedSchoolYear",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedSchoolYear = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      domProps: { value: _vm.currentYear - 2 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentYear - 2) +
                                          " - " +
                                          _vm._s(_vm.currentYear - 2 + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: { value: _vm.currentYear - 1 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentYear - 1) +
                                          " - " +
                                          _vm._s(_vm.currentYear - 1 + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: _vm.currentYear },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentYear) +
                                          " - " +
                                          _vm._s(_vm.currentYear + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: { value: _vm.currentYear + 1 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentYear + 1) +
                                          " - " +
                                          _vm._s(_vm.currentYear + 1 + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: { value: _vm.currentYear + 2 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentYear + 2) +
                                          " - " +
                                          _vm._s(_vm.currentYear + 2 + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "py-3",
                              class: {
                                "col-4":
                                  _vm.selectedPdfType?.pdfType ===
                                  "Student Report Cards",
                                "col-6":
                                  _vm.selectedPdfType?.pdfType !==
                                  "Student Report Cards",
                              },
                            },
                            [
                              _c("label", [_vm._v("School Term")]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedSchoolTerm,
                                      expression: "selectedSchoolTerm",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedSchoolTerm = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  !_vm.selectedSchoolYear
                                    ? _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null },
                                        },
                                        [_vm._v(" Select a School Year ")]
                                      )
                                    : _vm._e(),
                                  _c("option", [_vm._v("1")]),
                                  _c("option", [_vm._v("2")]),
                                  _c("option", [_vm._v("3")]),
                                  _c("option", [_vm._v("4")]),
                                  _c("option", [_vm._v("7")]),
                                ]
                              ),
                            ]
                          ),
                          _vm.selectedPdfType?.pdfType ===
                          "Student Report Cards"
                            ? _c("div", { staticClass: "col-4 py-3" }, [
                                _c("label", [_vm._v("Marking Period")]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedMP,
                                        expression: "selectedMP",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedMP = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    !_vm.selectedSchoolTerm
                                      ? _c(
                                          "option",
                                          {
                                            attrs: { selected: "" },
                                            domProps: { value: null },
                                          },
                                          [_vm._v(" Select a School Term ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "option",
                                      { attrs: { selected: "", value: "1" } },
                                      [_vm._v(" Marking Period 1 ")]
                                    ),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v(" Marking Period 2 "),
                                    ]),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v(" Marking Period 3 "),
                                    ]),
                                    _c("option", { attrs: { value: "4" } }, [
                                      _vm._v(" Marking Period 4 "),
                                    ]),
                                    _c("option", { attrs: { value: "5" } }, [
                                      _vm._v(" Marking Period 5 "),
                                    ]),
                                    _c("option", { attrs: { value: "6" } }, [
                                      _vm._v(" Marking Period 6 "),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "kt-portlet__foot" }, [
                        _c("div", { staticClass: "kt-form__actions" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-lg-6" }),
                            _c(
                              "div",
                              { staticClass: "col-lg-6 kt-align-right" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: { disabled: _vm.uploadDisabled },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.uploadFile()
                                      },
                                    },
                                  },
                                  [_vm._v(" Upload PDF ")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm.errorFiles.length !== 0
                ? _c(
                    "div",
                    { staticClass: "col-xl-6 col-lg-6 col-md-6" },
                    [
                      _c("ErrorBin", {
                        attrs: { "error-files": _vm.errorFiles },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
              _vm._m(2),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c(
                  "div",
                  { staticClass: "kt-widget5 p-2" },
                  _vm._l(_vm.schoolPdfUploads, function (pdfUpload) {
                    return _c(
                      "div",
                      {
                        key: `key_${pdfUpload.officialPdfUploadId}`,
                        staticClass: "kt-widget5__item",
                      },
                      [
                        _c("div", { staticClass: "kt-widget5__content" }, [
                          _c(
                            "div",
                            { staticClass: "kt-widget5__pic" },
                            [
                              _c("SVGIcon", {
                                staticStyle: { width: "42px" },
                                attrs: { name: "pdf" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "kt-widget5__section" }, [
                            _c(
                              "a",
                              {
                                staticClass: "kt-widget5__title",
                                attrs: { href: "#" },
                              },
                              [
                                pdfUpload.importStatus === "Pending"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--warning kt-badge--inline kt-badge--pill mr-3",
                                      },
                                      [_vm._v("Pending")]
                                    )
                                  : pdfUpload.importStatus === "Imported"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--primary kt-badge--inline kt-badge--pill mr-3",
                                      },
                                      [_vm._v("Imported")]
                                    )
                                  : pdfUpload.importStatus === "Error"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--danger kt-badge--inline kt-badge--pill mr-3",
                                      },
                                      [_vm._v("Error")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(pdfUpload.officialPdfTypeName) +
                                    " "
                                ),
                              ]
                            ),
                            _c("p", { staticClass: "kt-widget5__desc" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${pdfUpload.extSchoolYear}-${
                                      pdfUpload.extSchoolYear + 1
                                    } Term ${pdfUpload.extSchoolTerm} ${
                                      pdfUpload.officialPdfTypeName ===
                                      "Student Report Cards"
                                        ? `MP ${pdfUpload.extMarkingPeriod}`
                                        : ""
                                    }, Uploaded by ${pdfUpload.author} on ${
                                      pdfUpload.uploadedOn
                                    }`
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "kt-widget5__content" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "mr-5 btn btn-sm btn-label-danger btn-bold",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.removePdfUpload(pdfUpload)
                                },
                              },
                            },
                            [_vm._v(" Remove ")]
                          ),
                          _vm._m(3, true),
                          _c(
                            "div",
                            {
                              staticClass: "kt-widget5__stats ml-3 text-center",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "kt-widget5__number" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-switch kt-switch--outline kt-switch--icon kt-switch--info",
                                    },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          attrs: { type: "checkbox", name: "" },
                                          domProps: {
                                            checked: pdfUpload.showOnPortfolio,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setShowOnPortfolio(
                                                $event,
                                                pdfUpload
                                              )
                                            },
                                          },
                                        }),
                                        _c("span"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "kt-widget5__sales text-center",
                                },
                                [_vm._v(" Show On Portfolio ")]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Student PDF File Uploader "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v(" Browse ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Uploads "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget5__stats d-none" }, [
      _c("span", { staticClass: "kt-widget5__number" }, [_vm._v("200")]),
      _c("span", { staticClass: "kt-widget5__sales" }, [_vm._v("students")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
<div
    class="kt-container kt-grid__item kt-grid__item--fluid"
    @dragleave="onDragLeave"
    @dragover="onDragOver"
    @drop="onDrop"
>
    <div
        v-if="showDropZone"
        class="w-100"
    >
        <a class="kt-header__brand-logo w-100 text-center">
            <SVGIcon
                name="dropZone"
                class="logo loading"
            />

            <h1 class="kt-font-bolder">
                Drop files here to upload!
            </h1>
        </a>
    </div>
    <template v-else>
        <div
            v-if="status"
            class="row"
        >
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i
                            :class="{
                                'la la-check kt-font-success': status == 'success',
                                'la la-upload kt-font-warning': status == 'processing',
                                'la la-times kt-font-danger': status == 'danger',
                            }"
                        />
                    </div>
                    <div
                        class="alert-text"
                        :class="{
                            'kt-font-bold': status == 'danger'
                        }"
                    >
                        {{ status == 'success' ?
                            'PDF Upload Successful. Please allow time as they populate to student portfolios.'
                            : status == 'processing' ? 'PDF Upload Processing' : 'PDF Upload Failed' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="[ errorFiles.length == 0 ? 'col-12' : 'col-xl-6 col-lg-6 col-md-6']">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Student PDF File Uploader
                            </h3>
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <input
                            id="file-input"
                            type="file"
                            name="file"
                            style="display: none"
                            accept=".pdf"
                            @change="onFileBrowseSelect"
                        >
                        <div class="form-group row">
                            <div class="col-6 py-3">
                                <label>PDF File Type</label>
                                <select
                                    v-model="selectedPdfType"
                                    class="form-control"
                                >
                                    <option
                                        v-for="(pdfType, idx) in officialPdfTypes"
                                        :key="`pdfType_${pdfType.officialPdfTypeId}_${idx}`"
                                        :value="pdfType"
                                    >
                                        {{ pdfType.pdfType }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6 py-3">
                                <label>PDF File</label>
                                <div
                                    class="input-group"
                                    style="cursor: pointer"
                                    @click.stop.prevent="triggerFileBrowser"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="file ? `${file.name}` : 'Choose Files'"
                                        readonly="readonly"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            Browse
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div
                                class="py-3"
                                :class="{
                                    'col-4': selectedPdfType?.pdfType === 'Student Report Cards',
                                    'col-6': selectedPdfType?.pdfType !== 'Student Report Cards'
                                }"
                            >
                                <label>School Year</label>
                                <select
                                    v-model="selectedSchoolYear"
                                    class="form-control"
                                >
                                    <option :value="currentYear - 2">
                                        {{ currentYear - 2 }} - {{ currentYear - 2 + 1 }}
                                    </option>
                                    <option :value="currentYear - 1">
                                        {{ currentYear - 1 }} - {{ currentYear - 1 + 1 }}
                                    </option>
                                    <option
                                        selected
                                        :value="currentYear"
                                    >
                                        {{ currentYear }} - {{ currentYear + 1 }}
                                    </option>
                                    <option :value="currentYear + 1">
                                        {{ currentYear + 1 }} - {{ currentYear + 1 + 1 }}
                                    </option>
                                    <option :value="currentYear + 2">
                                        {{ currentYear + 2 }} - {{ currentYear + 2 + 1 }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="py-3"
                                :class="{
                                    'col-4': selectedPdfType?.pdfType === 'Student Report Cards',
                                    'col-6': selectedPdfType?.pdfType !== 'Student Report Cards'
                                }"
                            >
                                <label>School Term</label>
                                <select
                                    v-model="selectedSchoolTerm"
                                    class="form-control"
                                >
                                    <option
                                        v-if="!selectedSchoolYear"
                                        selected
                                        :value="null"
                                    >
                                        Select a School Year
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>7</option>
                                </select>
                            </div>
                            <div
                                v-if="selectedPdfType?.pdfType === 'Student Report Cards'"
                                class="col-4 py-3"
                            >
                                <label>Marking Period</label>
                                <select
                                    v-model="selectedMP"
                                    class="form-control"
                                >
                                    <option
                                        v-if="!selectedSchoolTerm"
                                        selected
                                        :value="null"
                                    >
                                        Select a School Term
                                    </option>
                                    <option
                                        selected
                                        value="1"
                                    >
                                        Marking Period 1
                                    </option>
                                    <option value="2">
                                        Marking Period 2
                                    </option>
                                    <option value="3">
                                        Marking Period 3
                                    </option>
                                    <option value="4">
                                        Marking Period 4
                                    </option>
                                    <option value="5">
                                        Marking Period 5
                                    </option>
                                    <option value="6">
                                        Marking Period 6
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="kt-form__actions">
                            <div class="row">
                                <div class="col-lg-6" />
                                <div class="col-lg-6 kt-align-right">
                                    <button
                                        class="btn btn-success"
                                        :disabled="uploadDisabled"
                                        @click.stop.prevent="uploadFile()"
                                    >
                                        Upload PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="errorFiles.length !== 0"
                class="col-xl-6 col-lg-6 col-md-6"
            >
                <ErrorBin :error-files="errorFiles" />
            </div>
        </div>

        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Uploads
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-widget5 p-2">
                    <div
                        v-for="pdfUpload in schoolPdfUploads"
                        :key="`key_${pdfUpload.officialPdfUploadId}`"
                        class="kt-widget5__item"
                    >
                        <div class="kt-widget5__content">
                            <div class="kt-widget5__pic">
                                <SVGIcon
                                    style="width: 42px"
                                    name="pdf"
                                />
                            </div>
                            <div class="kt-widget5__section">
                                <a
                                    href="#"
                                    class="kt-widget5__title"
                                >
                                    <span
                                        v-if="pdfUpload.importStatus === 'Pending'"
                                        class="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill mr-3"
                                    >Pending</span>
                                    <span
                                        v-else-if="pdfUpload.importStatus === 'Imported'"
                                        class="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill mr-3"
                                    >Imported</span>
                                    <span
                                        v-else-if="pdfUpload.importStatus === 'Error'"
                                        class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill mr-3"
                                    >Error</span>

                                    {{ pdfUpload.officialPdfTypeName }}
                                </a>
                                <p class="kt-widget5__desc">
                                    {{
                                        `${pdfUpload.extSchoolYear}-${pdfUpload.extSchoolYear + 1}
                                        Term ${pdfUpload.extSchoolTerm}
                                        ${pdfUpload.officialPdfTypeName === 'Student Report Cards' ? `MP ${pdfUpload.extMarkingPeriod}` : ''},
                                        Uploaded by ${pdfUpload.author} on ${pdfUpload.uploadedOn}`
                                    }}
                                </p>
                            </div>
                        </div>

                        <div class="kt-widget5__content">
                            <button
                                class="mr-5 btn btn-sm btn-label-danger btn-bold"
                                @click.stop.prevent="removePdfUpload(pdfUpload)"
                            >
                                Remove
                            </button>
                            <div class="kt-widget5__stats d-none">
                                <span class="kt-widget5__number">200</span>
                                <span class="kt-widget5__sales">students</span>
                            </div>
                            <div class="kt-widget5__stats ml-3 text-center">
                                <span class="kt-widget5__number">
                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--info">
                                        <label>
                                            <input
                                                type="checkbox"
                                                :checked="pdfUpload.showOnPortfolio"
                                                name=""
                                                @change="setShowOnPortfolio($event, pdfUpload)"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </span>
                                <span class="kt-widget5__sales text-center">
                                    Show On Portfolio
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import moment from 'moment-timezone';
import * as network from '../network';
import * as util from '../lib/uploads/util';
import SVGIcon from '../components/SVGIcon/SVGIcon.vue';
import ErrorBin from '../components/uploads/ErrorBin.vue';

export default {
    name: 'StudentPDFUploader',
    components: {
        SVGIcon,
        ErrorBin,
    },
    data() {
        return {
            officialPdfTypes: [],
            selectedPdfType: null,
            selectedSchoolYear: null,
            selectedSchoolTerm: null,
            selectedMP: null,
            status: null,
            officialPdfUploads: [],
            errorFiles: [],
            dragTimer: null,
            showDropZone: false,
            successMessage: null,
            processing: false,
            file: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        schoolPdfUploads() {
            return this.officialPdfUploads.map((upload) => {
                const pdf = upload;

                const officialPdfTypeName = this.officialPdfTypes.find((pdfType) => pdfType.officialPdfTypeId === pdf.officialPdfTypeId).pdfType;
                pdf.officialPdfTypeName = officialPdfTypeName;
                pdf.uploadedOn = moment(pdf.created).utc().format('MMM Do, YYYY HH:mm A');
                return pdf;
            }).filter((pdf) => !pdf.deleted).sort((a, b) => moment(b.created).diff(a.created));
        },
        currentYear() {
            return this.$store.state.user.school.schoolYear;
        },
        uploadDisabled() {
            const v = this;
            const {
                file, selectedPdfType, selectedSchoolYear, selectedSchoolTerm, selectedMP,
            } = v;
            if (!file) return true;
            if (v.status === 'processing') return true;
            if (!selectedPdfType || !selectedSchoolYear || !selectedSchoolTerm) return true;
            if (selectedPdfType.pdfType === 'Student Report Cards' && !selectedMP) return true;
            return false;
        },
    },
    mounted() {
        this.selectedSchoolYear = this.$store.state.user.school.schoolYear;
        this.selectedSchoolTerm = this.$store.state.user.school.schoolTerm;
        this.debouncePopulate();
    },
    methods: {
        debouncePopulate() {
            const { populate } = this;
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                populate();
            }, 250);
        },
        populate() {
            const v = this;
            const { schoolId, schoolTermId } = v.user.school;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
            };
            async.auto({
                pdfTypes(next) {
                    network.pdf.getPdfTypes(params, (err, res) => {
                        if (err) return next(err);
                        v.officialPdfTypes = res;
                        next(null, res);
                    });
                },
                schoolPdfUploads: ['pdfTypes', (results, next) => {
                    network.pdf.getPdfUploads(params, (err, res) => {
                        if (err) return next(err);
                        v.officialPdfUploads = res;
                        next(null, res);
                    });
                }],
            }, 5, (err) => {
                if (err) return console.error(err);
            });
        },
        onFileBrowseSelect(e) {
            [this.file] = e.target.files;
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        uploadFile() {
            const v = this;

            const {
                file, user,
                selectedPdfType, selectedSchoolYear,
                selectedSchoolTerm, selectedMP,
            } = v;

            const { schoolId, schoolTermId } = user.school;
            const { officialPdfTypeId } = selectedPdfType;
            if (!file) return;
            v.status = 'processing';

            async.auto({
                uploadFile(next) {
                    util.uploadToAWS('officialPdfs', file, user, (err, results) => {
                        if (err) return util.uploadError(file, user, next);
                        return next(err, results);
                    });
                },
                savePdf: ['uploadFile', (results, next) => {
                    const pdfBucketPath = results.uploadFile.parameters.fields.key;
                    if (!pdfBucketPath) return next(new Error('PDF Upload Failed'));

                    const params = {
                        url: { schoolId, schoolTermId, officialPdfTypeId },
                        body: {
                            pdfBucketPath,
                            extSchoolYear: selectedSchoolYear,
                            extSchoolTerm: selectedSchoolTerm,
                            extMarkingPeriod: selectedPdfType.pdfType === 'Student Report Cards' ? selectedMP : 1,
                        },
                    };
                    network.pdf.savePdf(params, next);
                }],
            }, 5, (err) => {
                if (err) v.status = 'danger';
                else {
                    v.status = 'success';
                    v.file = null;
                    v.selectedPdfType = null;
                    v.selectedSchoolYear = v.currentYear;
                    v.selectedSchoolTerm = null;
                    v.selectedMP = null;
                }
                v.populate();
                setTimeout(() => {
                    v.status = null;
                }, 1500);
            });
        },
        onDragOver(e) {
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            this.showDropZone = (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')));
        },
        onDragLeave(e) {
            e.stopPropagation();
            e.preventDefault();
            this.hideDropZone();
        },
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            [this.file] = e.dataTransfer.files;
            this.hideDropZone();
        },
        hideDropZone() {
            const v = this;
            if (v.dragTimer) clearTimeout(v.dragTimer);
            v.dragTimer = setTimeout(() => {
                v.showDropZone = false;
            }, 25);
        },
        setShowOnPortfolio(event, pdfUpload) {
            const v = this;
            const { schoolId, schoolTermId } = v.user.school;
            const { officialPdfUploadId } = pdfUpload;

            const showOnPortfolio = !pdfUpload.showOnPortfolio;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    officialPdfUploadId,
                },
                body: {
                    showOnPortfolio,
                },
            };

            network.pdf.updateShowPortfolio(params, (err, res) => {
                if (err) return v.showError('Error setting show on portfolio');
                v.officialPdfUploads = v.officialPdfUploads.map((pdf) => {
                    if (pdf.officialPdfUploadId === officialPdfUploadId) {
                        pdf.showOnPortfolio = showOnPortfolio;
                    }
                    return pdf;
                });
            });
        },
        removePdfUpload(pdfUpload) {
            const v = this;
            const { schoolId, schoolTermId } = v.user.school;
            const { officialPdfUploadId } = pdfUpload;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    officialPdfUploadId,
                },
            };

            network.pdf.deletePdf(params, (err, res) => {
                if (err) return v.showError('Error removing PDF');
                v.officialPdfUploads = v.officialPdfUploads.filter((pdf) => pdf.officialPdfUploadId !== officialPdfUploadId);
            });
        },
    },
};
</script>

<style scoped>
a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    color: #0f88ef;
    font-size: 2.8rem;
    opacity: 0.4;
}
</style>
